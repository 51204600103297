// /* global tw */
import React from 'react'
// import { css } from 'emotion'
// import BubbleWidget from '../components/BubbleWidget';
import '../tailwind.css';
import '../stitches.css';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

// const styles = {
// 	page: css(tw`flex justify-center items-center bg-grey-light`),
// 	container: css(
// 		tw`bg-white container my-8 mx-auto max-w-md shadow-lg rounded-lg overflow-hidden font-sans`
// 	),
// 	wrapper: css(tw`sm:flex sm:items-center px-6 py-4`),
// 	avatar: css(
// 		tw`block h-16 sm:h-24 rounded-full mx-auto mb-4 sm:mb-0 sm:mr-4 sm:ml-0`
// 	),
// 	textContainer: css(tw`text-center sm:text-left sm:flex-grow`),
// 	textSpacing: css(tw`mb-4`),
// 	company: css(tw`my-0 text-sm leading-tight text-grey-dark`),
// 	name: css(tw`my-0 text-xl leading-tight`),
// 	button: css(
// 		tw`bg-white border-purple border font-semibold hover:bg-purple hover:text-white leading-normal px-4 py-1 rounded-full text-purple text-xs`
// 	)
// }

export default() => (
	<div>
		<Nav/>

			<section className="border-t-2 border--grey-light font-sans">
				<div className="container m-auto flex flex-col md:flex-row max-w-xl">
					<div className="flex flex-col w-full lg:w-1/2 justify-center items-start py-8">
						<label for="label" className="uppercase">TaRGET - II consortium</label>
						<h1 className="my-4 font-normal">Our purpose</h1>
						<p className="leading-normal mb-4">The <strong>TaRGET(Toxicant Exposures and Responses by Genomic and Epigenomic Regulators of Transcription) </strong> Program is a research consortium funded by the National Institute of Environmental Health Sciences (NIEHS). The goal of the collaboration is to address the role of environmental exposures in disease pathogenesis as a function of epigenome perturbation, including understanding the environmental control of epigenetic mechanisms and assessing the utility of surrogate tissue analysis in mouse models of disease-relevant environmental exposures.</p>
						<a href="http://">
							<button className="bg-transparent hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent">Learn More</button>
						</a>
						</div>
						<div className="w-full lg:w-1/2 lg:py-6"><img src="/white-paper.png" alt='whitepaper_figure' className="w-full"/>
					</div>
				</div>
			</section>

			<section className="font-sans text-center bg-grey-lighter p-8 block">
				<div className="capitalize font-medium m-8"></div>
				<a href="https://dcc.targetepigenomics.org">
					<button className="bg-black hover:bg-grey-darkest text-white hover:text-white py-3 px-6 uppercase text-xs tracking-wide">Go to Data Portal</button>
				</a>
			</section>

			<Footer/>
	</div>
    
)